<template>
  <section>
    <div class="col-span-12 lg:col-span-6 mt-8">
      <div
      class="intro-y block sm:flex items-center h-10"
      style="min-height:95px;"
      >
        <h2 class="text-lg font-medium truncate mr-5 capitalize">Indicadores Operación</h2>
        <FiltroMesAno v-model="filtro" />
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y">
        <CardCarteraClientes />
      </div>
      <div class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y">
        <CardCarteraProveedores />
      </div>
      <div class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y">
        <CardRecaudos :year="filtro.year" :month="filtro.month" />
      </div>
      <div class="col-span-12 sm:col-span-6  xl:col-span-6 intro-y">
        <CardPagosEfectuados :year="filtro.year" :month="filtro.month" />
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 ">
      <!-- <div class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y">
        <CardTotales :year="filtro.year" :month="filtro.month" />
      </div> -->
      <!-- <CardFacturacion :year="filtro.year" :month="filtro.month" /> -->
      <!-- <CardSinFacturar :year="filtro.year" :month="filtro.month" /> -->
      <!-- <CardOvAbiertas :year="filtro.year" :month="filtro.month" />
      <CardComprasProveedores :year="filtro.year" :month="filtro.month" />
      <CardConsumoClientes :year="filtro.year" :month="filtro.month" />
      <CardConsumoSedes :year="filtro.year" :month="filtro.month" />
      <CardInventario :year="filtro.year" :month="filtro.month" /> -->
      <!-- <div class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y">
        <CardCarteraClientes />
      </div> -->
      <!-- <div class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y">
        <CardCarteraProveedores />
      </div> -->
      <!-- <div class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y">
        <CardRecaudos :year="filtro.year" :month="filtro.month" />
      </div> -->
    </div>
    <div class="col-span-12 lg:col-span-6 mb-8">
      <div class="intro-y block sm:flex items-center h-10" style="min-height:95px;">
        <h2 class="text-lg font-medium truncate mr-5 capitalize">Indicadores financieros</h2>
        <FiltroMesAno v-model="filtroFinanciero" :tiene-mes="false" />
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 ">
      <div class="col-span-12 sm:col-span-6 ">
        <TablaBalance :year="filtroFinanciero.year" />
      </div>
      <div class="col-span-12 sm:col-span-6 ">
        <TablaPerdidasGanacias :year="filtroFinanciero.year" />
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <!-- <div class="col-span-12 mt-5">
        <div class="intro-y block sm:flex items-center h-10" style="min-height:45px;">
          <h2 class="text-lg font-medium truncate mr-5 capitalize">Servicio al Cliente</h2>
        </div>
      </div> -->
      <!-- <div class="col-span-12 sm:col-span-6 lg:col-span-12">
        <GraficaPqrClasificacion :filters="filtroFinanciero" />
      </div> -->
      <!-- <div class="col-span-12 sm:col-span-6 lg:col-span-6 ">
        <GraficaPqrCliente :filters="filtroFinanciero" />
      </div> -->
      <!-- <div class="col-span-12 sm:col-span-6 lg:col-span-6 ">
        <GraficaPqrMedio :filters="filtroFinanciero" />
      </div> -->
    </div>
    <!-- <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 mt-8">
        <div class="intro-y block sm:flex items-center h-10" style="min-height:95px;">
          <h2 class="text-lg font-medium truncate mr-5 capitalize">Digiturno</h2>
          <FiltroDigiturno  @updateFilters="updateFilters"/>
        </div>
      </div>
    </div> -->
    <!-- <ContenedorDigiturno ref="contenedorDigiturnoRef" /> -->
  </section>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import FiltroMesAno from '../../components/filtroMesAno'
import { helper } from '../../../../../utils/helper'
// import FiltroClasificacionPqr from './components/FiltroClasificacionPqr'
// import FiltroDigiturno from './digiturno/filtrosDigiturno'
// import FiltroClasificacionPqr from './components/FiltroClasificacionPqr'
// import CardFacturacion from '../../components/cardFacturacion/Main'
// import CardSinFacturar from '../../components/cardSinFacturar/Main'
// import CardOvAbiertas from '../../components/cardOvAbiertas/Main'
// import CardComprasProveedores from '../../components/cardComprasProveedores/Main'
// import CardConsumoClientes from '../../components/cardConsumoCliente/Main'
// import CardConsumoSedes from '../../components/cardConsumoSede/Main'
// import CardInventario from '../../components/cardInventario/Main'
import TablaBalance from '../../components/cardBalance/Main'
import TablaPerdidasGanacias from '../../components/cuentaPerdidasGanancias/Main'
import CardCarteraClientes from '../../components/cardCarteraClientes/Main'
import CardCarteraProveedores from '../../components/cardCarteraProveedores/Main'
import CardPagosEfectuados from '../../components/cardPagosEfectuados/Main'
// import CardTotales from '../../components/cardTotales/Main'
import CardRecaudos from '../../components/cardRecaudos/Main'
import dayjs from 'dayjs'
// import GraficaPqrClasificacion from './components/GraficaPqrClasificacion/index'
// import GraficaPqrCliente from './components/GraficaPqrCliente/index'
// import GraficaPqrMedio from './components/GraficaPqrMedio/index'
// import ContenedorDigiturno from './digiturno/index'

export default defineComponent({
  components: {
    FiltroMesAno,
    // FiltroClasificacionPqr,
    // FiltroDigiturno,
    // FiltroClasificacionPqr,
    // CardFacturacion,
    // CardSinFacturar,
    // CardOvAbiertas,
    // CardComprasProveedores,
    // CardConsumoClientes,
    // CardConsumoSedes,
    // CardInventario,
    TablaBalance,
    TablaPerdidasGanacias,
    CardCarteraClientes,
    CardCarteraProveedores,
    CardPagosEfectuados,
    // CardTotales,
    CardRecaudos
    // GraficaPqrClasificacion,
    // GraficaPqrCliente,
    // GraficaPqrMedio
    // ContenedorDigiturno
  },
  setup () {
    const getYearNow = () => {
      const nowDay = new Date().getDate()
      if (nowDay >= 1 && nowDay <= 3) {
        if (dayjs().month() === 0) {
          return new Date().getFullYear() - 1
        }
      }
      return new Date().getFullYear()
    }
    const getMonthNow = () => {
      const nowDay = new Date().getDate()
      if (nowDay >= 1 && nowDay <= 3) {
        return dayjs().subtract(1, 'month').month() + 1
      }
      return new Date().getMonth() + 1
    }

    const filtro = ref({
      year: getYearNow(),
      month: getMonthNow()
    })

    const filtroFinanciero = ref({
      year: getYearNow(),
      month: null
    })
    const varTipo = ref()
    const contenedorDigiturnoRef = ref()
    const filtroTipo = ref({
      year: getYearNow(),
      month: null,
      tipo: varTipo
    })

    const updateFilters = (filtros) => {
      contenedorDigiturnoRef.value.fetchResults(filtros)
    }

    onMounted(() => {
      // contenedorDigiturnoRef.value.fetchResults({
      //   fecha_inicial: helper.formatDate(new Date(), 'YYYY-MM-DD'),
      //   fecha_final: helper.formatDate(new Date(), 'YYYY-MM-DD')
      // })
    })

    return {
      updateFilters,
      contenedorDigiturnoRef,
      filtroFinanciero,
      filtro,
      filtroTipo,
      varTipo,
      helper
    }
  }
})

</script>
<style lang="scss" scoped>

.table_cards{
  text-align: start;
  font-size: 0.8em;
  margin-top: 10px;
}
.btn-atras{
  padding-top: 4px;
  padding-bottom: 4px;
}
.color-azul{
  color: #1c3faa;
}
</style>
