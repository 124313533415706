<template>
  <div class="box p-5 cursor-pointer scroll-ajuste">
    <div class="w-full">
      <div v-if="estaCargando" class="rounded-md icono-carga p-8">
        <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
      </div>
      <template v-if="estado === 1">
        <div class="w-full font-medium">
          <div> Recaudos </div>
          <div> Vlr Recaudo </div>
        </div>
        <div @click="setEstado(2)">
          <!-- <div class="w-full font-medium">
            Importe
          </div> -->
          <div class="text-gray-600">
            {{$h.formatCurrency(info.importe)}}
          </div>
          <div class=" mt-1 w-full font-medium">
            Vlr Reconciliar
          </div>
          <div class="text-gray-600">
            {{$h.formatCurrency(info.total)}}
          </div>
        </div>
      </template>
      <template v-if="estado === 2">
        <div class="flex justify-between items-center">
          <div class="w-full font-medium">
            <div> Recaudos </div>
          </div>
          <div class="w-full flex justify-end mb-2">
            <button
            type="button"
            class="btn"
            @click="setEstado(1)"
            >
              <svg viewBox="0 0 24 24" data-v-d93e3f96="" style="width: 12px; height: 12px;"><path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" data-v-d93e3f96=""></path></svg>
            </button>
          </div>
        </div>
        <div class="w-full letra">
          <table class="w-full">
            <tr>
              <th class="text-left">Cliente</th>
              <th class="text-right">Total Vlr Recaudo</th>
              <th class="text-right">Total Por Reconciliar</th>
            </tr>
            <tr v-for="(row,k) in info.items" :key="k" >
              <td
              class="text-primary-1 dark:text-white"
              @click="setClienteSel(row.label)"
              >
                {{row.label}}
              </td>
              <td class="text-right">{{$h.formatCurrency(row.importe)}}</td>
              <td class="text-right">{{$h.formatCurrency(row.total)}}</td>
            </tr>
          </table>
        </div>
      </template>
      <template v-if="estado === 3">
        <div class="flex justify-between items-center">
          <div class="w-full font-medium">
            <div> Recaudos </div>
          </div>
          <div class="w-full flex justify-end mb-2">
            <button
            type="button"
            class="btn"
            @click="setEstado(2)"
            >
              <svg viewBox="0 0 24 24" data-v-d93e3f96="" style="width: 12px; height: 12px;"><path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" data-v-d93e3f96=""></path></svg>
            </button>
          </div>
        </div>
        <div class="w-full letra" style="overflow-x: auto;">
          <table class="w-full">
            <tr>
              <th
              class="text-left "
              style="min-width:70px;"
              >
                Fecha
              </th>
              <th
              class="text-right"
              style="min-width:50px;"
              >
                No. Ref
              </th>
              <th
              class="text-right"
              style="min-width:100px;"
              >
                Vlr Recaudo
              </th>
              <th
              class="text-right"
              style="min-width:100px;"
              >
                Por Reconciliar
              </th>
            </tr>
            <tr v-for="(row,m) in getRefs" :key="m">
              <td>{{$h.formatDate(row.fecha,'DD/MM/YYYY')}}</td>
              <td class="text-right">{{row.baseRef}}</td>
              <td
              class="text-right"
              style="width:150px;"
              >
                {{$h.formatCurrency(row.importe)}}
              </td>
              <td
              class="text-right"
              style="width:120px;"
              >
                {{$h.formatCurrency(row.total)}}
                </td>
            </tr>
          </table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent, watchEffect } from 'vue'
import {
  info,
  getData,
  estado,
  setEstado,
  estaCargando,
  setClienteSel,
  getRefs
  } from './Hook'
export default defineComponent({
  props: {
    year: {
      type: [String, Number],
      default: new Date().getFullYear()
    },
    month: {
      type: [String, Number],
      default: new Date().getMonth() + 1
    }
  },
  setup (props, context) {
    watchEffect(() => {
      getData({
        year: props.year,
        month: props.month
      })
    })
    return {
      setClienteSel,
      getRefs,
      estado,
      estaCargando,
      info,
      getData,
      setEstado
    }
  }
})
</script>

<style scoped>
.letra{
  font-size: 0.8em;
}
.icono-carga{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,0.75);
  height: 100%;
  z-index: 999999;
}
.scroll-ajuste{
  z-index: 9999;
  position: relative;
  max-height: 280px;
  overflow-y: auto;
}
</style>
