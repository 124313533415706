<template>
  <div class="w-full p-5 box rounded-md zoom-in">
    <div v-if="estaCargando" class="rounded-md icono-carga p-8">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <div class="w-full">
      <div class="capitalize font-medium">Cartera clientes</div>
    </div>
    <div
    v-if="estado === 1 "
    class="flex w-full flex-col letra"
    >
      <div
      v-for="(value,key) in getTotales"
      :key="key"
      class="flex justify-between uppercase"
      >
        <div class="text-primary-1 dark:text-white">{{key}}</div>
        <div class="">{{$h.formatCurrency(value)}}</div>
      </div>
      <div class="my-2 flex justify-between">
        <div
        class="text-primary-1 dark:text-white  rounded-md cursor-pointer w-1/12"
        >
          <div v-if="totalesExpandido" @click="setTotalesExpandido(false)">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
            </svg>
          </div>
          <div v-else @click="setTotalesExpandido(true)">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
            </svg>
          </div>
        </div>
        <div
        class="text-primary-1 dark:text-white  rounded-md cursor-pointer text-right w-1/2 "
        style="width:max-content;"
        @click="setEstado(2)"
        >
          Ver mas
        </div>
      </div>
    </div>
    <div v-if="estado === 2">
      <div class="flex justify-end  letra">
        <button
        type="button"
        class="btn btn-atras"
        title="Atras"
        @click="setEstado(1)"
        >
          <svg viewBox="0 0 24 24" data-v-4ef94f20="" style="width: 12px; height: 12px;"><path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" data-v-4ef94f20=""></path></svg>
        </button>
      </div>
      <div
      class="flex w-full justify-between letra"
      >
        <div class="font-bold">
          Cliente
        </div>
        <div class="text-right font-bold" style="width:110px; min-width:110px;">
          Total
        </div>
      </div>
      <div class="scroll-ajuste">
        <div
        v-for="(row,k) in info.items"
        :key="k"
        class="flex w-full justify-between letra"
        >
          <div
          class="text-primary-1 cursor-pointer dark:text-white"
          @click="setSeleccion(row,3)"
          >
            {{row.name}}
          </div>
          <div class="text-right" style="width:110px; min-width:110px;">
            {{$h.formatCurrency(row.saldo)}}
          </div>
        </div>
      </div>
    </div>
    <div v-if="estado === 3">
      <div class="flex justify-end  letra">
        <button
        type="button"
        class="btn btn-atras"
        title="Atras"
        @click="setEstado(2)"
        >
          <svg viewBox="0 0 24 24" style="width: 12px; height: 12px;"><path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" data-v-4ef94f20=""></path></svg>
        </button>
      </div>
      <table class="w-full letra">
        <tr
        v-for="(row,k) in seleccionado" :key="k"
        >
          <td v-if="k === 'name' ">Nombre</td>
          <td v-else>{{k}}</td>
          <td
          v-if="k !== 'name'"
          class="text-right"
          style="width:110px; min-width:110px;"
          >
            {{$h.formatCurrency(row)}}
          </td>
          <td
          v-else
          class="text-right"
          style="width:110px; min-width:110px;"
          >
            {{row}}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import {
  estaCargando,
  info,
  getData,
  setEstado,
  estado,
  seleccionado,
  setSeleccion,
  getTotales,
  setTotalesExpandido,
  totalesExpandido
  } from './Hook'
export default defineComponent({
  setup () {
    onMounted(() => {
      getData()
    })
    return {
      totalesExpandido,
      setTotalesExpandido,
      estaCargando,
      estado,
      info,
      getData,
      setEstado,
      seleccionado,
      setSeleccion,
      getTotales
    }
  }
})
</script>

<style scoped>
.letra{
  font-size: 0.8em;
}
.scroll-ajuste{
  z-index: 9999;
  position: relative;
  max-height: 295px;
  overflow-y: auto;
}
.icono-carga{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,0.75);
  height: 100%;
  z-index: 999999;
}

</style>
