<template>
  <div
  class="sm:ml-auto mt-3 sm:mt-0 relative text-gray-700 dark:text-gray-300"
  style="display:flex;"
  >
    <div class="flex align-baseline mr-2">
      <CalendarIcon class="my-auto inset-y-0 ml-3 left-0" size="36" />
    </div>
    <div class="border p-2">
      <div style="width: auto; display: flex;">
        <div class="mr-2">
          <label for="filterYear">Año</label>
          <select
          v-model="year"
          id="filterYear"
          class="form-control form-control-sm"
          @change="handleYear"
          >
            <option :value="null">--</option>
            <option v-for="(row,k) in anos" :key="k" :value="row">{{row}}</option>
          </select>
        </div>
        <div v-if="tieneMes" class="mr-2">
          <label for="">Mes</label>
          <select
          v-model="month"
          name=""
          id=""
          class="form-control form-control-sm"
          @change="handleMonth"
          >
            <option :value="null">--</option>
            <option v-for="(row,k) in meses" :key="k" :value="row.id">{{row.nombre}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    tieneMes: {
      type: Boolean,
      default: true
    }
  },
  setup (props, context) {
    const year = ref(props.modelValue.year)
    const month = ref(props.modelValue.month)

    const getYears = () => {
      const years = []
      const yearNow = new Date().getFullYear()
      for (let index = 0; index < 5; index++) {
        years.push(yearNow - index)
      }
      return years
    }

    const handleYear = (e) => {
      let value = null
      if (e.target.value) value = +e.target.value
      year.value = value
      emitir()
    }

    const handleMonth = (e) => {
      let value = null
      if (e.target.value) value = +e.target.value
      month.value = value
      emitir()
    }

    const emitir = () => {
      context.emit('update:modelValue', { year, month })
    }

    return {
      props,
      year,
      month,
      handleYear,
      handleMonth,
      anos: getYears(),
      meses: [
        {
          id: 1,
          nombre: 'Enero'
        },
        {
          id: 2,
          nombre: 'Febrero'
        },
        {
          id: 3,
          nombre: 'Marzo'
        },
        {
          id: 4,
          nombre: 'Abril'
        },
        {
          id: 5,
          nombre: 'Mayo'
        },
        {
          id: 6,
          nombre: 'Junio'
        },
        {
          id: 7,
          nombre: 'Julio'
        },
        {
          id: 8,
          nombre: 'Agosto'
        },
        {
          id: 9,
          nombre: 'Septiembre'
        },
        {
          id: 10,
          nombre: 'Octubre'
        },
        {
          id: 11,
          nombre: 'Noviembre'
        },
        {
          id: 12,
          nombre: 'Diciembre'
        }
      ]
    }
  }
})
</script>

<style>

</style>
