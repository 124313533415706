<template>
  <div class="w-full p-5 box rounded-md zoom-in">
    <div v-if="estaCargando" class="rounded-md icono-carga p-8">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <div class="w-full flex  justify-between">
      <div class="capitalize font-medium">Pagos Efectuados</div>

    </div>

    <div
    v-if="estado === 1 "
    class="flex w-full flex-col"
    >
      <div
      v-for="(row,key) in getTotales"
      :key="key"
      class="flex flex-col letra scroll-ajuste"
      >
        <div class="w-full flex  justify-between uppercase cursor-pointer" @click="row.visible=!row.visible ">
          <div class="text-primary-1 dark:text-white">{{row.label}}</div>
          <div class="">{{row.totalFormat}}</div>
        </div>
        <div  v-if="row.visible" class="w-full flex flex-col">
          <div class="flex w-full justify-between">
            <div style="width:40%;" class="font-bold pl-2">Proveedor</div>
            <div style="width:30%;" class="text-right font-bold">Valor</div>
          </div>
          <div v-for="(row3,m) in row.items" :key="m" class="w-full flex justify-between">
            <div style="width:40%;" class="text-left pl-2">{{row3.label}}</div>
            <div style="width:30%;" class="text-right">{{row3.valueFormat}}</div>
          </div>
        </div>

      </div>
      <div class="my-2 flex justify-center">
        <div
          class="text-primary-1 dark:text-white letra rounded-md cursor-pointer text-right w-full "
          style="width:max-content;"
          @click="setEstado(2)"
        >
          Ver mas
        </div>
      </div>
    </div>
    <div v-if="estado === 2">
      <div class="flex justify-end  letra">
        <button
          type="button"
          class="btn btn-atras"
          title="Atras"
          @click="setEstado(1)"
        >
          <svg viewBox="0 0 24 24" data-v-4ef94f20="" style="width: 12px; height: 12px;"><path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" data-v-4ef94f20=""></path></svg>
        </button>
      </div>
      <div
        class="flex w-full justify-between letra"
      >
        <div class="font-bold">
          Nombre
        </div>
        <div class="text-right font-bold" style="width:110px; min-width:110px;">
          Total
        </div>
      </div>
      <div class="scroll-ajuste">
        <div
          v-for="(row,k) in info.detailClient"
          :key="k"
          class="flex w-full justify-between letra"
        >
          <div
            class="text-primary-1 cursor-pointer dark:text-white"
            @click="setSeleccion(row,3)"
          >
            {{row.label}}
          </div>
          <div class="text-right" style="width:110px; min-width:110px;">
            {{$h.formatCurrency(row.valueFormat)}}
          </div>
        </div>
      </div>
    </div>
    <div v-if="estado === 3">
      <div class="flex justify-end  letra">
        <button
          type="button"
          class="btn btn-atras"
          title="Atras"
          @click="setEstado(2)"
        >
          <svg viewBox="0 0 24 24" style="width: 12px; height: 12px;"><path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" data-v-4ef94f20=""></path></svg>
        </button>
      </div>
      <table class="w-full letra">
        <tr
          v-for="(row,k) in seleccionado" :key="k"
        >
          <td v-if="k === 'name' ">Nombre</td>
          <td v-else>{{k}}</td>
          <td
            v-if="k !== 'name'"
            class="text-right"
            style="width:110px; min-width:110px;"
          >
            {{ typeof row === 'number' ? $h.formatCurrency(row) : row }}
          </td>
          <td
            v-else
            class="text-right"
            style="width:110px; min-width:110px;"
          >
            {{row}}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent, watchEffect } from 'vue'
import {
  info,
  getData,
  setEstado,
  estado,
  seleccionado,
  setSeleccion,
  buscar, getItems,
  estaCargando,
  getTotales,
  totalesExpandido,
  setTotalesExpandido,
  handleExcelDownload
  } from './Hook'
export default defineComponent({
  name: 'carteraProveedores',
  props: {
    year: {
      type: [String, Number],
      default: new Date().getFullYear()
    },
    month: {
      type: [String, Number],
      default: new Date().getMonth() + 1
    }
  },
  setup (props, context) {
    watchEffect(() => {
      getData({
        year: props.year,
        month: props.month
      })
    })
    // onMounted(() => {
    //   getData()
    // })
    return {
      estaCargando,
      buscar,
      estado,
      info,
      getData,
      setEstado,
      seleccionado,
      setSeleccion,
      getItems,
      getTotales,
      totalesExpandido,
      setTotalesExpandido,
      handleExcelDownload
    }
  }
})
</script>

<style scoped>
.letra{
  font-size: 0.8em;
}
.scroll-ajuste{
  z-index: 9999;
  position: relative;
  max-height: 295px;
  overflow-y: auto;
}
.icono-carga{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,0.75);
  height: 100%;
  z-index: 999999;
}
</style>
