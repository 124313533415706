import { fetchBalance } from './Service'
import { ref } from 'vue'

const info = ref({
  items: {
    Activos: {
      Detalle: [],
      Label: '-',
      Total: 0
    },
    Pasivos: {
      Detalle: [],
      Label: '-',
      Total: 0
    },
    Patrimonio: {
      Detalle: [],
      Label: '-',
      Total: 0
    }
  },
  paramsQuery: {}
})
const estaCargando = ref(true)
const nivel = ref(1)
const getInfo = (queryParams = {}) => {
  estaCargando.value = true
  return fetchBalance(queryParams).then(({ status, data }) => {
    estaCargando.value = false
    if (status === 200) {
      info.value = data
    }
  }).catch(err => {
    estaCargando.value = false
    console.error(err.message)
  })
}
export {
  estaCargando,
  nivel,
  info,
  getInfo
}
