<template>
  <div class="w-full p-5 box rounded-md zoom-in">
    <div v-if="estaCargando" class="rounded-md icono-carga p-8">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <div class="w-full flex  justify-between">
      <div class="capitalize font-medium">Cartera proveedores</div>

    </div>

    <div
    v-if="estado === 1 "
    class="flex w-full flex-col"
    >
      <div
      v-for="(row,key) in getTotales"
      :key="key"
      class="flex flex-col letra scroll-ajuste"
      >
        <div class="w-full flex  justify-between uppercase cursor-pointer" @click="row.visible=!row.visible ">
          <div class="text-primary-1 dark:text-white">{{row.label}}</div>
          <div class="">{{row.totalFormat}}</div>
        </div>
        <div  v-if="row.visible" class="w-full flex flex-col">
          <div class="flex w-full justify-between">
            <div style="width:40%;" class="font-bold pl-2">Proveedor</div>
            <div style="width:30%;" class="text-right font-bold">Valor</div>
          </div>
          <div v-for="(row3,m) in row.items" :key="m" class="w-full flex justify-between">
            <div style="width:40%;" class="text-left pl-2">{{row3.label}}</div>
            <div style="width:30%;" class="text-right">{{row3.valueFormat}}</div>
          </div>
        </div>

      </div>
      <div class="my-2 flex justify-between">
        <div
        class="text-primary-1 dark:text-white rounded-md cursor-pointer w-1/12"
        >
          <div v-if="totalesExpandido" @click="setTotalesExpandido(false)">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
            </svg>
          </div>
          <div v-else @click="setTotalesExpandido(true)">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
            </svg>
          </div>
        </div>
        <div
          class="text-primary-1 dark:text-white letra rounded-md cursor-pointer text-right w-1/2 "
          style="width:max-content;"
          @click="setEstado(2)"
        >
          Ver mas
        </div>
        <div
          class="mr-2 cursor-pointer text-primary-1 dark:text-white"
          title="Descargar"
          @click="handleExcelDownload"
        >
          <svg style="width:24px;height:24px" class="text-green-900" viewBox="0 0 24 24">
            <path fill="currentColor" d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" />
          </svg>
        </div>
      </div>
    </div>
    <div v-if="estado === 2">
      <div class="flex justify-end  letra">
        <button
          type="button"
          class="btn btn-atras"
          title="Atras"
          @click="setEstado(1)"
        >
          <svg viewBox="0 0 24 24" data-v-4ef94f20="" style="width: 12px; height: 12px;"><path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" data-v-4ef94f20=""></path></svg>
        </button>
      </div>
      <div
        class="flex w-full justify-between letra"
      >
        <div class="font-bold">
          Cliente
        </div>
        <div class="text-right font-bold" style="width:110px; min-width:110px;">
          Total
        </div>
      </div>
      <div class="scroll-ajuste">
        <div
          v-for="(row,k) in info.detailClient"
          :key="k"
          class="flex w-full justify-between letra"
        >
          <div
            class="text-primary-1 cursor-pointer dark:text-white"
            @click="setSeleccion(row,3)"
          >
            {{row.NombreSN}}
          </div>
          <div class="text-right" style="width:110px; min-width:110px;">
            {{$h.formatCurrency(row.Total)}}
          </div>
        </div>
      </div>
    </div>
    <div v-if="estado === 3">
      <div class="flex justify-end  letra">
        <button
          type="button"
          class="btn btn-atras"
          title="Atras"
          @click="setEstado(2)"
        >
          <svg viewBox="0 0 24 24" style="width: 12px; height: 12px;"><path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" data-v-4ef94f20=""></path></svg>
        </button>
      </div>
      <table class="w-full letra">
        <tr
          v-for="(row,k) in seleccionado" :key="k"
        >
          <td v-if="k === 'name' ">Nombre</td>
          <td v-else>{{k}}</td>
          <td
            v-if="k !== 'name'"
            class="text-right"
            style="width:110px; min-width:110px;"
          >
            {{ typeof row === 'number' ? $h.formatCurrency(row) : row }}
          </td>
          <td
            v-else
            class="text-right"
            style="width:110px; min-width:110px;"
          >
            {{row}}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import {
  info,
  getData,
  setEstado,
  estado,
  seleccionado,
  setSeleccion,
  buscar, getItems,
  estaCargando,
  getTotales,
  totalesExpandido,
  setTotalesExpandido,
  handleExcelDownload
  } from './Hook'
export default defineComponent({
  name: 'carteraProveedores',
  setup () {
    onMounted(() => {
      getData()
    })
    return {
      estaCargando,
      buscar,
      estado,
      info,
      getData,
      setEstado,
      seleccionado,
      setSeleccion,
      getItems,
      getTotales,
      totalesExpandido,
      setTotalesExpandido,
      handleExcelDownload
    }
  }
})
</script>

<style scoped>
.letra{
  font-size: 0.8em;
}
.scroll-ajuste{
  z-index: 9999;
  position: relative;
  max-height: 295px;
  overflow-y: auto;
}
.icono-carga{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,0.75);
  height: 100%;
  z-index: 999999;
}
</style>
