import { computed, ref } from 'vue'
import { fetchBalance } from './Service'
import dayjs from 'dayjs'

const info = ref({
  importe: 0,
  total: 0,
  items: []
})
const estaCargando = ref(true)
const estado = ref(1)
const setEstado = (value) => {
  estado.value = value
}
const getData = (query = {}) => {
  estaCargando.value = true
  return fetchBalance(query).then(({ status, data }) => {
    estaCargando.value = false
    if (status === 200) {
      info.value = data.response
    }
  }).catch(err => {
    console.error(err)
    estaCargando.value = false
  })
}
const clienteSel = ref('')

const getRefs = computed(() => {
  const cliente = info.value.items.find(a => a.label === clienteSel.value)
  if (cliente === undefined) return []
  return cliente.refs
})

const setClienteSel = (value) => {
  estado.value = 3
  clienteSel.value = value
}

const formatDate = (value) => {
  return dayjs(value).format('YYYY')
}

export {
  estado,
  info,
  estaCargando,
  clienteSel,
  getRefs,
  formatDate,
  setClienteSel,
  getData,
  setEstado
}
