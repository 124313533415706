<template>
  <section class="w-full p-2 rounded-md letra box">
    <div v-if="estaCargando" class="rounded-md icono-carga p-8">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <div class="w-full flex justify-between items-center mt-1">
      <div class="font-medium truncate capitalize" style="font-size:14px;">
        Balance
      </div>
      <div>
        <label for="nivel" class="mr-2">Nivel</label>
        <select v-model="nivel" id="nivel" class="border px-3 py-1 rounded-md text-primary-1">
          <option :value="1">1</option>
          <option :value="2">2</option>
        </select>
      </div>
    </div>
    <div
    class="flex items-center font-bold my-2"
    style="line-height: 1.1;"
    >
      <div class="capitalize w-full">
        cuenta
      </div>
      <div class="capitalize" style="min-width: 120px; width:120px;">
        Saldo
      </div>
      <div class="capitalize text-left " style="min-width:70px; width:70px;">
        %
      </div>
    </div>
    <template v-for="(row, k) in info.items" :key="k">
      <div class="w-full flex my-1">
        <div class="w-full text-gray-600 uppercase">
          {{row.Label}}
        </div>
        <div class="capitalize text-gray-600 " style="min-width: 120px; width: 120px;">
          {{$h.formatCurrency(row.Total)}}
        </div>
        <div class="capitalize text-gray-600 " style="min-width:70px; width: 70px;">
          100%
        </div>
      </div>
      <template v-if="nivel === 2">
        <div
        v-for="(detalle, m) in row.Detalle"
        :key="m"
        class="flex w-full"
        >
          <div class="pl-2 w-full dark:text-white text-primary-1 uppercase">
            {{detalle.LabelCuenta}}
          </div>
          <div class="" style="min-width: 120px;">
            {{$h.formatCurrency(detalle.Total)}}
          </div>
          <div class="" style="min-width: 70px;">
            {{detalle.Porcentaje || 0}}%
          </div>
        </div>
      </template>
    </template>
  </section>
</template>

<script>
import { defineComponent, watchEffect } from 'vue'
import { getInfo, info, nivel, estaCargando } from './Hook'
export default defineComponent({
  props: {
    year: {
      type: [Number, String],
      default: null
    }
  },
  setup (props, context) {
    watchEffect(() => {
      getInfo({ year: props.year })
    })
    return {
      estaCargando,
      info,
      nivel
    }
  }
})
</script>

<style scoped>
.letra{
  font-size: 0.8em !important;
}
.icono-carga{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,0.75);
  height: 100%;
  z-index: 999999;
}
</style>
