import { computed, ref } from 'vue'
import { fetchPagosEfectuados } from './Service'

const info = ref({
  groupTotal: [],
  detailClient: []
})
const estaCargando = ref(true)
const buscar = ref('')
const estado = ref(1)
const seleccionado = ref({})

const setEstado = (value) => {
  estado.value = value
}
const totalesExpandido = ref(false)
const setTotalesExpandido = (state) => {
  console.log(state)
  totalesExpandido.value = state
}
const getTotales = computed(() => {
  if (totalesExpandido.value) {
    return info.value.groupTotal
  }

  return info.value.groupTotal.filter(f => ['Total'].includes(f.label))
})
const getData = (query = {}) => {
  estaCargando.value = true
  return fetchPagosEfectuados(query).then(({ status, data }) => {
    estaCargando.value = false
    if (status === 200) {
      info.value = data
    }
  }).catch(err => {
    console.error(err)
    estaCargando.value = false
  })
}
const setSeleccion = (value, state = null) => {
  if (state) {
    estado.value = state
  }
  seleccionado.value = value
}

const getItems = computed(() => {
  return info.value.items.map(a => {
    a.Proveedor = a.Proveedor ? a.Proveedor : 'N/A'
    return a
  }).filter(a => a.Proveedor.toLocaleLowerCase().includes(buscar.value.toLocaleLowerCase()))
})

const handleExcelDownload = () => {
  window.open(`${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD_DAILYTECH}/operation/pagos-efectuados/export`, '_blank')
}
export {
  handleExcelDownload,
  estaCargando,
  buscar,
  estado,
  info,
  seleccionado,
  getItems,
  getTotales,
  totalesExpandido,
  setTotalesExpandido,
  getData,
  setEstado,
  setSeleccion
}
